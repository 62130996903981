<template>
  <div class="page-content">
    <div class="container-fluid">
      <Breadcrumb
        :pgTitle="$t('nav.settings')"
        :pgIcon="'bx bx-wrench'"
        :refs="refs"
        :pgCurrent="pgCurrent"
        :addNew="false"
      >
      </Breadcrumb>

      <div class="row">
        <Loading v-if="pgLoading"> </Loading>

        <div v-if="!pgLoading" class="col-8">
          <FormAvailableConvention
            :times_1hour="row.times_1hour"
            :times_2hour="row.times_2hour"
            :times_3hour="row.times_3hour"
            :times_4hour="row.times_4hour"
            :times_5hour="row.times_5hour"
            :times_6hour="row.times_6hour"
            :times_7hour="row.times_7hour"
            :times_8hour="row.times_8hour"
            :price_1hour="row.price_1hour"
            :price_2hour="row.price_2hour"
            :price_3hour="row.price_3hour"
            :price_4hour="row.price_4hour"
            :price_5hour="row.price_5hour"
            :price_6hour="row.price_6hour"
            :price_7hour="row.price_7hour"
            :price_8hour="row.price_8hour"
            v-on:timesChange="handleTimesChange"
          >
          </FormAvailableConvention>
        </div>

        <div v-if="!pgLoading" class="col-4">
          <CardMonths
            :months="row.months"
            v-on:monthsChange="handleMonthsChange"
          >
          </CardMonths>

          <CardWeekDays
            :weekDays="row.weekDays"
            v-on:weekDaysChange="handleWeekDaysChange"
          >
          </CardWeekDays>

          <CardStatus
            :status="row.status"
            v-on:statusChange="handleStatusChange"
          >
          </CardStatus>
        </div>
      </div>

      <Buttons
        v-if="!pgLoading"
        :btnLoading="btnLoading"
        :btnCurrent="btnCurrent"
        v-on:submitClicked="handelSubmit"
        v-on:cancelClicked="handleCancel"
      >
      </Buttons>
    </div>
  </div>
</template>

<script>
import iziToast from "izitoast";

export default {
  name: "EditOrNew",
  components: {
    Breadcrumb: () => import("@/components/Breadcrumb.vue"),
    Loading: () => import("@/components/Loading.vue"),
    FormAvailableConvention: () =>
      import("@/components/FormAvailableConvention.vue"),
    CardMonths: () => import("@/components/CardMonths.vue"),
    CardWeekDays: () => import("@/components/CardWeekDays.vue"),
    CardStatus: () => import("@/components/CardStatus.vue"),
    Buttons: () => import("@/components/Buttons.vue"),
  },
  data() {
    return {
      // auth
      auth: {
        role: "",
        access_token: "",
      },

      // row
      row: {
        weekDays: [],
        months: [],

        times_1hour: [],
        times_2hour: [],
        times_3hour: [],
        times_4hour: [],
        times_5hour: [],
        times_6hour: [],
        times_7hour: [],
        times_8hour: [],

        price_1hour: "",
        price_2hour: "",
        price_3hour: "",
        price_4hour: "",
        price_5hour: "",
        price_6hour: "",
        price_7hour: "",
        price_8hour: "",
        status: 1,
      },

      msgCurrent: this.$route.params.id ? "Updated" : "Created",
      btnCurrent: this.$route.params.id ? "Update" : "Create",
      methodType: this.$route.params.id ? "PUT" : "POST",
      pgLoading: this.$route.params.id ? true : false,
      pgCurrent: this.$route.params.id ? "Edit" : "Add New",
      btnLoading: false,

      refs: "settings",
    };
  },
  mounted() {},
  created() {
    // AccessToken & Role
    if (localStorage.getItem("access_token")) {
      this.auth.access_token = localStorage.getItem("access_token");
    }
    if (localStorage.getItem("role")) {
      this.auth.role = localStorage.getItem("role");
    }

    if (this.$route.query.parent_id) {
      this.row.parent_id = this.$route.query.parent_id;
    }

    if (this.$route.query.slug) {
      this.row.slug = this.$route.query.slug;
    }

    if (this.$route.params.id) {
      this.fetchRow();
    }
  },
  methods: {
    // fetch Row
    fetchRow() {
      this.pgLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
        locale: this.$i18n.locale,
      };
      const options = {
        url: window.baseURL + "/" + this.refs + "/" + this.$route.params.id,
        method: "GET",
        data: {},
      };
      this.axios(options)
        .then((res) => {
          this.pgLoading = false;

          this.row.months = res.data.row.months;
          this.row.weekDays = res.data.row.weekDays;

          this.row.times_1hour = res.data.row.times_1hour;
          this.row.times_2hour = res.data.row.times_2hour;
          this.row.times_3hour = res.data.row.times_3hour;
          this.row.times_4hour = res.data.row.times_4hour;
          this.row.times_5hour = res.data.row.times_5hour;
          this.row.times_6hour = res.data.row.times_6hour;
          this.row.times_7hour = res.data.row.times_7hour;
          this.row.times_8hour = res.data.row.times_8hour;

          this.row.price_15min = res.data.row.price_15min;
          this.row.price_30min = res.data.row.price_30min;
          this.row.price_60min = res.data.row.price_60min;
          this.row.price_1hour = res.data.row.price_1hour;
          this.row.price_2hour = res.data.row.price_2hour;
          this.row.price_3hour = res.data.row.price_3hour;
          this.row.price_4hour = res.data.row.price_4hour;
          this.row.price_5hour = res.data.row.price_5hour;
          this.row.price_6hour = res.data.row.price_6hour;
          this.row.price_7hour = res.data.row.price_7hour;
          this.row.price_8hour = res.data.row.price_8hour;
          this.row.status = res.data.row.status;
          console.log(this.row.times_1hour);
        })
        .catch((err) => {
          // 403 Forbidden
          if (err.response && err.response.status == 401) {
            this.clearLocalStorage();
            this.$router.push({ name: "login" });
          } else if (err.response && err.response.status == 403) {
            this.$router.push({ name: "forbidden" });
          } else {
            this.btnLoading = false;
            iziToast.warning({
              icon: "bx bx-angry",
              title: "",
              message: err.response ? err.response.data.message : "" + err,
            });
          }
        })
        .finally(() => {});
    },

    // handle edit Or New submit
    handelSubmit() {
      this.btnLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.access_token,
      };
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const options = {
        url: this.$route.params.id
          ? window.baseURL + "/" + this.refs + "/" + this.$route.params.id
          : window.baseURL + "/" + this.refs,
        method: this.methodType,
        data: {
          months: this.row.months,
          weekDays: this.row.weekDays,

          times_1hour: this.row.times_1hour,
          times_2hour: this.row.times_2hour,
          times_3hour: this.row.times_3hour,
          times_4hour: this.row.times_4hour,
          times_5hour: this.row.times_5hour,
          times_6hour: this.row.times_6hour,
          times_7hour: this.row.times_7hour,
          times_8hour: this.row.times_8hour,

          price_1hour: this.row.price_1hour,
          price_2hour: this.row.price_2hour,
          price_3hour: this.row.price_3hour,
          price_4hour: this.row.price_4hour,
          price_5hour: this.row.price_5hour,
          price_6hour: this.row.price_6hour,
          price_7hour: this.row.price_7hour,
          price_8hour: this.row.price_8hour,

          status: this.row.status,
        },
      };
      console.log(options);

      this.axios(options, config)
        .then(() => {
          this.btnLoading = false;
          iziToast.success({
            icon: "bx bx-wink-smile",
            title: "",
            message:
              this.btnCurrent == "Update"
                ? "تم التعديل بنجاح"
                : "تم الإضافة بنجاح",
          });

          if (this.$route.query.parent_id) {
            this.$router.push({
              path:
                "/" +
                this.refs +
                "/show?parent_id=" +
                this.row.parent_id +
                "&slug=" +
                this.row.slug,
            });
          } else {
            this.$router.push({ name: this.refs });
          }
        })
        .catch((err) => {
          // 403 Forbidden
          if (err.response && err.response.status == 401) {
            this.clearLocalStorage();
            this.$router.push({ name: "login" });
          } else if (err.response && err.response.status == 403) {
            this.$router.push({ name: "forbidden" });
          } else {
            this.btnLoading = false;
            iziToast.warning({
              icon: "bx bx-angry",
              title: "",
              message: err.response ? err.response.data.message : "" + err,
            });
          }
        })
        .finally(() => {});
    },

    // clear Local Storage
    clearLocalStorage() {
      localStorage.removeItem("access_token");
      localStorage.removeItem("avatar");
      localStorage.removeItem("username");
      localStorage.removeItem("encrypt_id");
      localStorage.removeItem("role");
    },

    // Cancel
    handleCancel() {
      if (confirm("هل انت متأكد؟")) {
        this.$router.push({ name: this.refs });
      }
    },

    // handel Changes from child components
    handleTimesChange(event) {
      this.row.times_1hour = event.times_1hour;
      this.row.times_2hour = event.times_2hour;
      this.row.times_3hour = event.times_3hour;
      this.row.times_4hour = event.times_4hour;
      this.row.times_5hour = event.times_5hour;
      this.row.times_6hour = event.times_6hour;
      this.row.times_7hour = event.times_7hour;
      this.row.times_8hour = event.times_8hour;

      this.row.price_15min = event.price_15min;
      this.row.price_30min = event.price_30min;
      this.row.price_60min = event.price_60min;
      this.row.price_1hour = event.price_1hour;
      this.row.price_2hour = event.price_2hour;
      this.row.price_3hour = event.price_3hour;
      this.row.price_4hour = event.price_4hour;
      this.row.price_5hour = event.price_5hour;
      this.row.price_6hour = event.price_6hour;
      this.row.price_7hour = event.price_7hour;
      this.row.price_8hour = event.price_8hour;
    },
    handleMonthsChange(event) {
      this.row.months = event.months;
    },
    handleWeekDaysChange(event) {
      this.row.weekDays = event.weekDays;
    },
    handleStatusChange(event) {
      this.row.status = event.status;
    },
  },
};
</script>
